import cleanObject from './cleanObject'

if (typeof window === 'undefined') {
  window = {}
}

export function getApiStatus() {
  return window.localStorage.getItem('global_api_health_status')
}

export function getDashboardTableHeaderMode() {
  return window.localStorage.getItem('dashboard_table_header_mode')
}

export function getDashSidebarCollapseStatus() {
  return window.localStorage.getItem('dash_sidebar_is_collapsed')
}

export function toggleDashSidebarCollapseStatus() {
  const isCollapsed = window.localStorage.getItem('dash_sidebar_is_collapsed') === 'true'
  const newStatus = isCollapsed ? 'false' : 'true'
  window.localStorage.setItem('dash_sidebar_is_collapsed', newStatus)
}

export function setDashboardTableHeaderMode(mode) {
  if (mode) {
    window.localStorage.setItem('dashboard_table_header_mode', mode)
  } else {
    window.localStorage.removeItem('dashboard_table_header_mode')
  }
}

export function setApiStatus(status) {
  if (status) {
    window.localStorage.setItem('global_api_health_status', status)
  } else {
    window.localStorage.removeItem('global_api_health_status')
  }
}

export function getFlashMessage() {
  return window.localStorage.getItem('flash_message')
}

export function removeFlashMessage() {
  window.localStorage.removeItem('flash_message')
}

export function setLocalStorageValue({ key, value }) {
  window.localStorage.setItem(key, value)
}

export function getLocalStorageValue(key) {
  return window.localStorage.getItem(key)
}

export function setLocalStorageValues(currentUser) {
  window.localStorage.setItem('email', currentUser.data.email)
  window.localStorage.setItem('mobile', currentUser.data.mobile)
  window.localStorage.setItem('location', currentUser.data.location)
  window.localStorage.setItem('f_name', currentUser.data.f_name)
  window.localStorage.setItem('l_name', currentUser.data.l_name)
  window.localStorage.setItem('orgDetails', JSON.stringify(currentUser.data.orgDetails))
  window.localStorage.setItem(
    'preferredCategoryIds',
    JSON.stringify(currentUser.data.preferredCategoryIds)
  )
  window.localStorage.setItem('user_id', currentUser.data.id)

  if (currentUser.data.avatar_url) {
    window.localStorage.setItem('avatar_url', currentUser.data.avatar_url)
  }
}

export function updateOrgDetails(data = []) {
  window.localStorage.setItem('orgDetails', JSON.stringify(data))
}

export function updateLocalStorageValues(data) {
  window.localStorage.setItem('email', data.email)
  window.localStorage.setItem('mobile', data.mobile)
  window.localStorage.setItem('f_name', data.f_name)
  window.localStorage.setItem('username', data.username)
  window.localStorage.setItem('l_name', data.l_name)
  window.localStorage.setItem('avatar_url', data.avatar_url)
  window.localStorage.setItem('location', data.location)
  window.localStorage.setItem('user_id', data.id)
  window.localStorage.setItem('preferredCategoryIds', JSON.stringify(data.preferredCategoryIds))
  window.localStorage.setItem('orgDetails', JSON.stringify(data.orgDetails))
}

export function getLocalStorageValues() {
  let orgDetails = ''
  let preferredCategoryIds = ''

  try {
    orgDetails = JSON.parse(window.localStorage.getItem('orgDetails'))
  } catch (err) {
    orgDetails = ''
  }

  try {
    preferredCategoryIds = JSON.parse(window.localStorage.getItem('preferredCategoryIds'))
  } catch (err) {
    preferredCategoryIds = ''
  }

  return cleanObject({
    email: window.localStorage.getItem('email'),
    mobile: window.localStorage.getItem('mobile'),
    location: window.localStorage.getItem('location'),
    f_name: window.localStorage.getItem('f_name'),
    l_name: window.localStorage.getItem('l_name'),
    username: window.localStorage.getItem('username'),
    avatar_url: window.localStorage.getItem('avatar_url'),
    orgDetails,
    preferredCategoryIds,
    id: window.localStorage.getItem('user_id')
  })
}

export function clearLocalStorageValues() {
  window.localStorage.removeItem('email')
  window.localStorage.removeItem('f_name')
  window.localStorage.removeItem('l_name')
  window.localStorage.removeItem('username')
  window.localStorage.removeItem('mobile')
  window.localStorage.removeItem('user_id')
  window.localStorage.removeItem('avatar_url')
  window.localStorage.removeItem('orgDetails')
}

export function identifyVisitorAsOrgMember(orgId) {
  if (!orgId) return true
  if (orgId === '') return true

  window.localStorage.setItem('identified_as_member_of_org_id', `${orgId}`)
}

export function getOrgMemberAssociationId() {
  return window.localStorage.getItem(`identified_as_member_of_org_id`)
}
